<template>
  <div id="BoxFight">
    <!-- <leftFloat :tabList="tabList" @currentChange="currentChange" /> -->
    <div id="BoxFight-box">
      <member @changeCurrent="changeCurrent" :current="current" v-if="current == 0 || current == 1 || current == 2" />
      <!-- 箱子遮罩层 -->
      <van-overlay :lock-scroll="false" @mousewheel.prevent.native="() => {}" :show="XZshow">
        <div class="wrapper">
          <div class="block">
            <div class="block-T">
              <p>{{ TreasureName }}</p>
              <span @click="CloseXZshow">×</span>
            </div>
            <div class="Box-List">
              <div class="Box-List-item" v-for="(item, index) in Treasure_box_prize" :key="index">
                <div class="pic">
                  <div class="zhao">
                    <img :src="item.lv_bg_image" alt="" />
                  </div>
                  <img :src="item.cover" alt="" />
                </div>
                <div class="name">{{ item.name }}</div>
                <!-- <div class="money"><img src="@/assets/images/PublicImg/JinBi.png" alt=""><span>{{item.bean}}</span></div> -->
              </div>
            </div>
            <div class="boxbtn">
              <p @click="CloseXZshow" style="background-color: #ffd322; color: #000">
                确定
              </p>
            </div>
          </div>
        </div>
      </van-overlay>

      <!-- 规则遮罩层 -->
      <van-overlay :lock-scroll="false" @mousewheel.prevent.native="() => {}" :show="RuleShow">
        <div class="wrapper">
          <div class="wrapper-box">
            <div class="wrapper-box-top"></div>
            <div class="cancel">
              <img src="@/assets/images/PublicImg/GuanBi.png" alt="" @click="ChangeRuleShow" />
            </div>
            <span>关于对战</span>
            <p>
              1
              创建大乱斗时，可以选择乱斗人数（1V1，3人对战和4人对战），一场乱斗最多可以选择
              6 个盲盒。房间一但创建，不可取消。
            </p>
            <p>
              2
              乱斗入场费用即本场乱斗开启的盲盒价格总和，支付费用后，即视为加入房间，中途退出房间，并不会退还盲盒或入场费。
            </p>
            <p>
              3
              当一场乱斗有多名赢家均分奖励时，非本人所得饰品将被兑换为硬币发放到赢家的账户余额。
            </p>
            <p>
              4
              每场游戏的结果受到（创建时间，玩家ID，玩家位置和开始时间等）多方面因素影响，为完全随机产生的结果。任意盲盒奖励均可以验证公平性。
            </p>
            <p style="margin-top: 30px">
              声明：tmskins将根据实际运营情况保留随时调整返佣比例的权利，以上活动最终解释权归tmskins所有!
            </p>
          </div>
        </div>
      </van-overlay>
    </div>
  </div>
</template>

<script>
import leftFloat from "@/components/left-float/index.vue";
import drop from "@/components/drop/index.vue";
import BoxItem from "./components/BoxItem.vue";
import member from "./components/member.vue";
import topTen from "./components/topTen.vue";
import record from "../classicsBox/components/record.vue";
import md5 from "blueimp-md5";
import { mapActions } from "vuex";
import { Arena_History, Arena_Me_History } from "@/network/api.js";
import { tabList2, tableRecordList3, dropBattle, dropRecord } from "./index.js";
export default {
  name: "BoxFight",
  data() {
    return {
      dropBattle,
      dropRecord,
      tableData: {},
      tableRecordList3,
      tabList: tabList2,
      current: 0,
      battle: true, //当前游戏/我的游戏
      RuleShow: false, //规则遮罩层
      //推送相关
      clearTimeSet: null,
      // Ar_List: [], //对战列表
      Treasure_box_prize: [], //宝箱奖品数据
      TreasureName: "",
      VsStart: {},

      XZshow: false,
      radio: "1",
      Key_Vs: 1,
    };
  },
  //activated
  created() {
    // this.current = this.$route.query.activeTab || 0;
    this.GetArena_History();
  },
  watch: {
    $route: {
      handler(newVal) {
        if (newVal.query?.tabType) {
          this.currentChange(newVal.query.tabType * 1);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    changeCurrent(index) {
      this.current = index;
    },
    //全部历史记录
    GetArena_History() {
      Arena_History().then((res) => {
        this.tableData = {
          total: Number(res.data.data.total),
          list: res.data.data.data.map((item) => {
            return {
              ...item,
              time: item.updated_at,
              userList: item.game_arena_player.map((vitem) => {
                return {
                  ...vitem,
                  ...vitem.user,
                };
              }),
              count: item.box_num,
              boxList: item.game_arena_box.map((zitem) => {
                return {
                  ...zitem,
                  cover: zitem.box && zitem.box.intact_cover,
                };
              }),
              price: item.total_bean,
            };
          }),
        };
      });
    },
    ImgRoute(str) {
      return localStorage.getItem("static_file_url") + str;
    },
    currentChange(i) {
      this.current = i;
      // this.$router.push({
      //   path: "/Battle",
      //   query: {
      //     activeTab: i,
      //   },
      // });
    },
    //打开箱子弹框
    OpenXZshow(item) {
      this.XZshow = true;
      this.TreasureName = item.name;
      this.Treasure_box_prize = item.box_awards;
    },
    //关闭箱子弹框
    CloseXZshow() {
      this.XZshow = false;
    },

    //接收房间信息(websocket)
    // Accept(data) {
    //   this.Ar_List.unshift(data);
    // },

    //对战开始
    AddStartGroup(data) {
      // this.VsStart = data
      this.GetKaiShiVsData(data);
    },

    // //对战结束
    // AddEndGroup(data){
    // 	this.GetJieShuVsData(data)
    // },
    //路由跳转
    Jump(str) {
      this.$router.push(str);
    },

    //对战切换
    BattleSwitch(v) {
      this.battle = v;
    },
    //规则遮罩层
    ChangeRuleShow() {
      this.RuleShow = !this.RuleShow;
    },

    //删除选中箱子
    deleteItem(item, index) {
      let A_Arr = this.choiceList.filter((i) => {
        return i.id == item.id;
      });

      if (A_Arr.length < 2) {
        let K = this.hotboxlist.findIndex((value) => value.id == A_Arr[0].id);
        this.$refs.Active[K].style = "border: 1px solid transparent;";
      }

      this.choiceList.splice(index, 1);
    },

    ...mapActions([
      // 'GetJieShuVsData',
      "GetKaiShiVsData",
    ]),
  },
  components: {
    BoxItem,
    leftFloat,
    member,
    record,
    drop,
    topTen,
  },
};
</script>

<style lang="scss" scoped>
.search-box {
  width: 100%;
  display: flex;

  .battle-search {
    width: 1.02rem;
    height: 0.4rem;
    text-align: center;
    line-height: 0.4rem;
    background: #0d2b2d;
    font-size: 0.18rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    box-shadow: inset 0px 0px 7px 3px #267b81;
    cursor: pointer;
  }
}

.user-img {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;

  img {
    width: 0.41rem;
    height: 0.41rem;
    border-radius: 50%;
  }
}

.box-list {
  display: flex;
  align-items: center;
  height: 100%;
}

.rake-1 {
  width: 40px;
  font-size: 0.16rem;
  margin-right: 0.16rem;
  color: #fff;
  height: auto;
  background-image: url("../../assets/images/Vs/1.png");
  background-size: 100% 100%;
}

.rake-2 {
  width: 40px;
  font-size: 0.16rem;
  margin-right: 0.16rem;
  color: #fff;
  height: auto;
  background-image: url("../../assets/images/Vs/2.png");
  background-size: 100% 100%;
}

.rake-3 {
  width: 40px;
  font-size: 0.16rem;
  margin-right: 0.16rem;
  color: #fff;
  height: auto;
  background-image: url("../../assets/images/Vs/3.png");
  background-size: 100% 100%;
}

.rake {
  width: 40px;
  font-size: 0.16rem;
  margin-right: 0.16rem;
  color: #fff;
  height: auto;
}

.ranking-list-items {
  width: 100%;
}

.ranking-list-item {
  overflow: hidden;
  width: 100%;
  padding: 0.1rem 0.1rem;
  position: relative;
  display: flex;
  align-items: center;
  font-size: 0.14rem;
}

.ranking-list {
  padding-bottom: 0.8rem;
  margin-top: 0.16rem;
  border-radius: 0.1rem;
  color: #c3c3e2;
  background-image: url("../../assets/images/Vs/rank-bg.png");
  background-size: 100% 100%;
  text-align: center;
  min-height: 7.83rem;
}

.vsbox_item {
  .active {
    color: #f9d240;
    background: #1d003a !important;
    text-shadow: -2px 0 0 rgb(0 0 0);
  }

  .vsbox_item_jf {
    cursor: pointer;
    flex-shrink: 0;
    width: 52.5%;
    height: 100%;
    background: #333;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s;
    padding: 5%;
    -webkit-clip-path: polygon(90% 0, 100% 100%, 0 100%, 0 0);
    clip-path: polygon(90% 0, 100% 100%, 0 100%, 0 0);
  }

  .vsbox_item_SL {
    cursor: pointer;
    position: relative;
    flex-shrink: 0;
    width: 52.5%;
    height: 100%;
    background: #333;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s;
    left: -4.5%;
    padding: 5%;
    -webkit-clip-path: polygon(100% 0, 100% 100%, 10% 100%, 0 0);
    clip-path: polygon(100% 0, 100% 100%, 10% 100%, 0 0);
  }

  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  .vsbox_pic {
    width: 2.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;

    img {
      vertical-align: middle;
    }
  }

  // .vsbox_data {
  //   width: 1.5rem;

  //   p {
  //     font-size: 16px;
  //     font-weight: bold;
  //     color: #ce9827;

  //     img {
  //       width: 18px;
  //       height: 18px;
  //       vertical-align: middle;
  //       margin-bottom: 2px;
  //       margin-right: 2px;
  //     }
  //   }

  //   span {
  //     font-size: 14px;
  //   }
  // }
}

.Vs-right-bg {
  .ranking-list {
    padding-bottom: 0.8rem;
    margin-top: 0.16rem;
    border-radius: 0.1rem;
    color: #c3c3e2;
    background-image: url("../../assets/images/Vs/rank-bg.png");
    background-size: 100% 100%;
    text-align: center;
    min-height: 7.83rem;
  }

  .ranking-top {
    margin-top: 0.2rem;
    width: 100%;
    height: 100%;
  }

  .ranking-list-items {
    width: 100%;
  }

  .ranking-list-item {
    overflow: hidden;
    width: 100%;
    padding: 0.1rem 0.1rem;
    position: relative;
    display: flex;
    align-items: center;
    font-size: 0.14rem;
  }

  .rake-1 {
    width: 40px;
    font-size: 0.16rem;
    margin-right: 0.16rem;
    color: #fff;
    height: auto;
    background-image: url("../../assets/images/Vs/1.png");
    background-size: 100% 100%;
  }

  .rake-2 {
    width: 40px;
    font-size: 0.16rem;
    margin-right: 0.16rem;
    color: #fff;
    height: auto;
    background-image: url("../../assets/images/Vs/2.png");
    background-size: 100% 100%;
  }

  .rake-3 {
    width: 40px;
    font-size: 0.16rem;
    margin-right: 0.16rem;
    color: #fff;
    height: auto;
    background-image: url("../../assets/images/Vs/3.png");
    background-size: 100% 100%;
  }

  .rake {
    width: 40px;
    font-size: 0.16rem;
    margin-right: 0.16rem;
    color: #fff;
    height: auto;
  }

  .list-items-name {
    width: 30%;
  }

  .list-items-jf {
    width: 30%;
    text-align: left;
  }

  .list-items-user {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;

    & img {
      width: 100%;
      height: 100%;
    }
  }
}

.top1 {
  background-size: 100% 100%;
  text-align: center;

  &-bg {
    margin: 0 auto;
    width: 80px;
    height: 80px;
    background-size: 100% 100%;
    background-image: url("../../assets/images/Vs/no1.png");
  }

  & div {
    font-size: 12px;
  }

  & img {
    width: 63%;
    height: 67%;
    margin-left: 1%;
    border-radius: 50%;
    margin-top: 13px;
  }
}

.top2 {
  display: flex;
  justify-content: space-around;
}

.paiming {
  padding: 60px 50px;
  background-image: url("../../assets/images/Vs/Group163@2x.png");
  background-size: 100% 100%;
}

.VSbox_list1 {
  width: 2.8rem;
  margin-top: 0.4rem;
  margin-right: 0.2rem;
  background-size: 100% 100%;
}

.paiming {
  padding: 60px 50px;
  background-image: url("../../assets/images/Vs/Group163@2x.png");
  background-size: 100% 100%;
}

#BoxFight-box {
  width: 100%;
  // padding-top: 0.6rem;

  .PageNum {
    width: 100%;
    margin-top: 0.4rem;

    li {
      background: #111111 !important;

      &.active {
        background: #4854c9 !important;
      }

      &:hover {
        color: #fff !important;
      }
    }

    .btn-prev,
    .btn-next {
      background: #111111;
    }
  }

  .pic {
    margin-top: 0.2rem;
    width: 100%;
    margin: 0 auto;

    img {
      width: 100%;
    }
  }

  //切换动画
  /* 设置持续时间和动画函数 */
  .slide-fade-enter-active {
    transition: all 0.3s ease;
  }

  .slide-fade-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
  }

  .slide-fade-enter,
  .slide-fade-leave-to

  /* .slide-fade-leave-active for below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }

  .BoxList-info {
    width: 100%;
    height: 0.5rem;
    margin-top: 0.4rem;
    display: flex;
    align-items: center;
    font-size: 0.14rem;
    color: #777777;

    .XZ {
      width: 8.9rem;
      padding-left: 0.15rem;
      box-sizing: border-box;
    }

    p {
      width: 1.7rem;
      text-align: center;
    }
  }

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    .block {
      width: 8rem;
      height: 6.7rem;
      background-color: #20222b !important;
      border-radius: 0.06rem;
      padding: 0.2rem;
      box-sizing: border-box;

      .block-T {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
          font-size: 0.24rem;
          color: #fff;
        }

        span {
          font-size: 0.28rem;
          color: rgba($color: #ffffff, $alpha: 0.6);
          cursor: pointer;
        }
      }

      .Box-List {
        width: 100%;
        height: 5rem;
        margin: 0.2rem 0 0.4rem;
        overflow: auto;
        display: flex;
        flex-wrap: wrap;

        .Box-List-item {
          width: 1.8rem;
          height: 2.3rem;
          position: relative;
          background: rgba(0, 0, 0, 0.2);
          border-radius: 0.04rem;
          margin-right: 0.1rem;
          margin-bottom: 0.1rem;
          cursor: pointer;
          overflow: hidden;

          &:nth-child(4n) {
            margin-right: 0;
          }

          &:hover {
            .ceng {
              opacity: 1;
            }
          }

          .pic {
            width: 1.8rem;
            height: 1.5rem;
            margin: 0.1rem auto 0;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;

            .zhao {
              width: 100%;
              height: 100%;
              position: absolute;
              left: 0;
              top: 0;
              display: flex;
              justify-content: center;
              align-items: center;

              img {
                max-width: 60%;
                vertical-align: middle;
              }
            }

            img {
              max-width: 100%;
              max-height: 100%;
              position: relative;
              z-index: 10;
            }
          }

          .name {
            width: 100%;
            text-align: center;
            color: #9e9e9e;
            font-size: 0.14rem;
          }

          .money {
            width: 100%;
            margin-top: 0.1rem;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              width: 0.2rem;
              height: 0.2rem;
              vertical-align: middle;
            }

            span {
              font-size: 0.14rem;
              color: #75dc9e;
            }
          }
        }
      }

      .Box-List::-webkit-scrollbar {
        /*滚动条整体样式*/
        width: 4px;
        /*高宽分别对应横竖滚动条的尺寸*/
        height: 4px;
        scrollbar-arrow-color: red;
      }

      .Box-List::-webkit-scrollbar-thumb {
        /*滚动条里面小方块*/
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: rgba(0, 0, 0, 0.6);
        scrollbar-arrow-color: red;
      }

      .Box-List::-webkit-scrollbar-track {
        /*滚动条里面轨道*/
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 0;
        background: rgba(0, 0, 0, 0.1);
      }

      .boxbtn {
        width: 100%;
        height: 0.35rem;
        display: flex;
        justify-content: flex-end;

        p {
          width: 0.7rem;
          height: 0.35rem;
          background: #4854c9;
          font-size: 0.14rem;
          color: #fff;
          text-align: center;
          line-height: 0.35rem;
          cursor: pointer;
        }
      }
    }

    .wrapper-box {
      .wrapper-box-top {
        width: 100%;
        height: 0.03rem;
        background: linear-gradient(
          90deg,
          transparent,
          rgba(255, 255, 255, 0.4),
          transparent
        );
      }

      width: 7.5rem;
      height: 3.8rem;
      position: relative;
      border: 1px solid rgba(255, 255, 255, 0.6);
      background-color: rgba(0, 0, 0, 0.7);
      border-radius: 0.08rem;
      overflow: hidden;
      padding: 0 0.35rem;
      box-sizing: border-box;

      span {
        display: block;
        text-align: center;
        font-size: 0.24rem;
        margin: 0.2rem 0 0.2rem;
      }

      p {
        font-size: 0.14rem;
        margin-bottom: 0.1rem;
        line-height: 1.75em;
      }

      .Tutorial {
        width: 100%;
        height: 100%;
        box-sizing: border-box;

        .title {
          width: 100%;
          height: 0.95rem;
          line-height: 0.95rem;
          text-align: center;
          font-size: 0.26rem;
        }

        .Tutorial-box {
          width: 100%;
          display: flex;
          justify-content: space-between;

          .JianTou {
            margin-top: 0.2rem;
          }

          .Tutorial-item {
            width: 1.6rem;
            text-align: center;

            .pic {
              width: 0.8rem;
              height: 0.8rem;
              margin: 0 auto;

              img {
                width: 100%;
                height: 100%;
              }
            }

            p {
              font-size: 0.16rem;
              color: #fff;
              margin-top: 0.1rem;
            }

            .info {
              color: rgba(255, 255, 255, 0.6);
              font-size: 0.14rem;
              line-height: 1.75em;
            }
          }
        }
      }

      .cancel {
        width: 0.5rem;
        height: 0.5rem;
        position: absolute;
        top: 0;
        right: 0;
        text-align: center;
        line-height: 0.5rem;

        img {
          width: 0.15rem;
          cursor: pointer;
        }
      }
    }
  }

  .el-drawer {
    width: 25% !important;
  }

  .el-drawer:focus {
    outline: none;
  }

  .el-drawer__body {
    background-color: #20222b !important;
    padding: 0.05rem 0.2rem;
    box-sizing: border-box;

    .Num_select {
      width: 100%;
      height: 0.5rem;
      border-radius: 0.04rem;
      overflow: hidden;
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        width: 1.2rem;
        height: 0.4rem;
        color: #fff;
        background: #bbb;
        border-radius: 0.04rem;
        text-align: center;
        line-height: 0.4rem;
        margin: 0 0.05rem;
        font-size: 0.16rem;
        cursor: pointer;

        &.active {
          background: #ffd322;
          color: #000;
        }
      }
    }

    .case_list_box {
      width: 100%;
      background-color: #2d303d !important;
      padding: 0.1rem;
      box-sizing: border-box;
      border-radius: 0.04rem;
      margin-top: 0.1rem;

      .small_tit {
        font-size: 0.22rem;
      }

      .case_list {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        height: 2.9rem;
        overflow: auto;
        margin-top: 0.1rem;

        .case_list_item {
          width: 31%;
          background-color: #20222b !important;
          border-radius: 0.04rem;
          overflow: hidden;
          cursor: pointer;
          margin-bottom: 0.1rem;
          box-sizing: border-box;
          cursor: pointer;
          position: relative;

          &:hover {
            .ceng {
              opacity: 1;
            }
          }

          .ceng {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: rgba($color: #000000, $alpha: 0.1);
            display: flex;
            justify-content: center;
            align-items: center;
            opacity: 0;
            transition: 0.3s;

            p {
              width: 0.6rem;
              height: 0.6rem;
              border-radius: 50%;
              background: rgba($color: #ffffff, $alpha: 0.2);
              color: #fff;
              text-align: center;
              line-height: 0.6rem;
              font-size: 0.18rem;
            }
          }

          .pic {
            width: 60%;
            margin: 0.05rem auto 0;

            img {
              max-width: 100%;
            }
          }

          p {
            font-size: 0.14rem;
            color: #fff;
            text-align: center;
          }

          span {
            display: block;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0.05rem 0 0.1rem;

            img {
              width: 0.18rem;
              height: 0.18rem;
            }

            font-size: 0.12rem;
            color: #75cd66;
          }
        }
      }

      .case_list::-webkit-scrollbar {
        /*滚动条整体样式*/
        width: 4px;
        /*高宽分别对应横竖滚动条的尺寸*/
        height: 4px;
        scrollbar-arrow-color: red;
      }

      .case_list::-webkit-scrollbar-thumb {
        /*滚动条里面小方块*/
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: rgba(0, 0, 0, 0.6);
        scrollbar-arrow-color: red;
      }

      .case_list::-webkit-scrollbar-track {
        /*滚动条里面轨道*/
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 0;
        background: rgba(0, 0, 0, 0.1);
      }
    }

    .Selected_list_box {
      width: 100%;
      background-color: #2d303d !important;
      padding: 0.1rem;
      box-sizing: border-box;
      border-radius: 0.04rem;
      margin-top: 0.1rem;

      .small_tit {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 0.22rem;

        p {
          display: inline-block;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 0.1rem;
          color: #75cd66;

          img {
            width: 0.25rem;
            height: 0.25rem;
            margin-right: 0.03rem;
          }
        }
      }

      .out_box {
        position: relative;

        .below {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;

          .below_item {
            width: 31%;
            height: 1.1rem;
            background: #20222b !important;
            border-radius: 0.04rem;
            overflow: hidden;
            margin-top: 0.1rem;
            box-sizing: border-box;
          }
        }

        .Select_list {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;

          &::after {
            display: block;
            content: "";
            width: 31%;
          }

          .Select_list_item {
            width: 31%;
            height: 1.1rem;
            border-radius: 0.04rem;
            overflow: hidden;
            margin-top: 0.1rem;
            box-sizing: border-box;
            cursor: pointer;
            position: relative;

            &:hover {
              .ceng {
                opacity: 1;
              }
            }

            .ceng {
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              background: rgba($color: #000000, $alpha: 0.1);
              display: flex;
              justify-content: center;
              align-items: center;
              opacity: 0;
              transition: 0.3s;

              p {
                width: 0.6rem;
                height: 0.6rem;
                border-radius: 50%;
                background: rgba($color: #ffffff, $alpha: 0.2);
                color: #fff;
                text-align: center;
                line-height: 0.6rem;
                font-size: 0.18rem;
              }
            }

            .pic {
              width: 60%;

              img {
                max-width: 100%;
              }
            }

            p {
              font-size: 0.14rem;
              text-align: center;
            }
          }
        }
      }
    }

    .batch {
      width: 100%;
      background-color: #2d303d !important;
      padding: 0.1rem;
      box-sizing: border-box;
      border-radius: 0.04rem;
      margin-top: 0.1rem;
      display: flex;
      align-items: center;

      .small_tit {
        font-size: 0.22rem;
        margin-right: 0.3rem;
      }

      .radio_box {
        flex: 1;

        .el-radio {
          margin-right: 20px;
          color: #fff;
        }

        .el-radio__label {
          padding-left: 3px;
        }
      }
    }

    .CJ_btn {
      width: 100%;
      box-sizing: border-box;
      margin-top: 0.1rem;
      display: flex;
      justify-content: flex-end;

      p {
        width: 1.5rem;
        height: 0.4rem;
        background: #ffd322;
        font-size: 0.18rem;
        border-radius: 0.04rem;
        color: #000;
        text-align: center;
        line-height: 0.4rem;
        cursor: pointer;
      }
    }
  }
}
</style>
