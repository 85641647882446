<template>
  <div class="top-ten">
    <img src="@/assets/images/battle/ten-title.png" alt="" />
    <div class="search-box">
      <div class="search-l">
        <div
          class="btn"
          :class="index == priceCurrent?'ac':''"
          @click="SearchChange(index, item.id)"
          v-for="(item, index) in tenSearchPrice"
          :key="index"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="search-r">
        <div
          class="btn"
           :class="index == dayCurrent?'ac':''"
          @click="SearchChange(index, item.id, 'day')"
          v-for="(item, index) in tenSearchDay"
          :key="index"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <div class="ten-content">
      <div class="ten-item" v-for="(item, index) in tenBoxList" :key="index">
        <div class="top">
          <div class="l">
            <img src="@/assets/images/battle/ten-icon.png" alt="" width="40%" />
            {{ item.type == 1 ? "官方" : "主播" }}
          </div>
          <div class="r">{{ item.count }}回合</div>
        </div>
        <div class="center">
          <div class="img-box">
            <div class="img" v-for="(i, j) in item.userList" :key="j">
              <img src="@/assets/images/battle/icon3.png" alt="" class="icon" v-if="i.win" />
              <img :src="i.avatar" alt="" class="pic" />
            </div>
          </div>
          <div class="price-box">{{ item.price }} <span></span>{{ item.price }}</div>
        </div>
        <div class="bottom">
          <div class="small-box" v-for="(b, bIndex) in item.boxList" :key="bIndex">
            <img :src="ImgRoute(b.cover)" alt="" width="80%" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { tenSearchDay, tenSearchPrice, tenBoxList } from "../index.js";
export default {
  data() {
    return {
      tenSearchDay,
      tenSearchPrice,
      tenBoxList,
      priceCurrent: 0,
      dayCurrent: 0,
    };
  },
  methods: {
    ImgRoute(str) {
      return localStorage.getItem("static_file_url") + str;
    },
    SearchChange(i, id, type = "price") {
      if (type == "price") {
        this.priceCurrent = i;
        return;
      }
      this.dayCurrent = i;
    },
  },
};
</script>
<style lang="scss" scoped>
.top-ten {
  min-height: 100vh;
  .search-box {
    display: flex;
    justify-content: space-between;
    .search-l,
    .search-r {
      display: flex;
      .btn {
        width: 1.12rem;
        height: 0.39rem;
        background: #000000;
        border-radius: 0.1rem;
        box-shadow: inset 0px 0px 7px 3px #267b81;
        font-size: 0.16rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        line-height: 0.39rem;
        text-align: center;
        margin-left: 0.09rem;
        cursor: pointer;
      }
      .ac {
        box-shadow: inset 0px 0px 7px 3px #4d0000;
      }
    }
  }
  .ten-content {
    margin-top: 0.32rem;
    display: flex;
    flex-wrap: wrap;
    .ten-item {
      margin-bottom: 0.2rem;
      margin-right: 0.2rem;
      width: 2.64rem;
      height: 2.21rem;
      box-sizing: border-box;
      overflow: hidden;
      background: url(../../../assets/images/battle/ten-box-bg.png) no-repeat;
      background-size: 100% 100%;
      &:nth-child(5n) {
        margin-right: 0;
      }
      .top {
        display: flex;
        justify-content: space-between;
        font-size: 0.14rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        padding: 0.2rem 0.2rem 0;
        .l {
          display: flex;
          align-items: center;
          img {
            width: 0.13rem;
            height: 0.14rem;
            margin-right: 0.06rem;
          }
        }
      }
      .center {
        display: flex;
        flex-direction: column;
        align-items: center;
        .img-box {
          display: flex;
          margin-top: 0.25rem;
          margin-bottom: 0.12rem;
          .img {
            box-sizing: border-box;
            width: 0.46rem;
            height: 0.46rem;
            background: #d4defe;
            border: 0.01px solid #0099ff;
            border-radius: 50%;
            position: relative;
            margin-right: 0.11rem;
            .icon {
              position: absolute;
              top: -0.2rem;
              left: 50%;
              transform: translateX(-50%);
              width: 0.22rem;
              height: 0.17rem;
            }
            .pic {
              width: 100%;
              height: 100%;
              border-radius: 50%;
              border: 0.01px solid #0099ff;
            }
          }
        }
        .price-box {
          font-size: 0.16rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
        }
      }
      .bottom {
        margin-top: 0.12rem;
        box-sizing: border-box;
        margin-left: 0.1rem;
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
        .small-box {
          display: inline-block;
          width: 0.52rem;
          height: 0.45rem;
          background: #d4defe;
          margin-right: 0.02rem;
          text-align: center;
          line-height: 0.45rem;
          img {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
          }
        }
        &::-webkit-scrollbar {
          height: 0.08rem;
        }
        &::-webkit-scrollbar-track {
          /*滚动条里面轨道*/
          border-radius: 0;
          background: #9999cc;
        }
        &::-webkit-scrollbar-thumb {
          /*滚动条里面小方块*/
          border-radius: 0.03rem;
          background: #666699;
        }
      }
    }
  }
}
</style>
