<template>
  <div class="item-mod" :class="`status${item.status}`" @click="itemClick(item.status, item.id)" v-if="item">
    <div class="text-box">
      <span>{{ item.type == 1 ? "虚拟对战" : "虚拟对战" }}</span>
      <span>{{ item.box_num }}回合</span>
    </div>
    <div class="people-num">
      <div class="user" v-for="index in item.user_num" :key="index">
        <img v-if="
            item.status === 2 &&
            item.win_user_id?.length &&
            item.win_user_id.includes(batterRoom[index - 1].user.id)
          " class="winner" src="@/assets/images/battle/winner.png" alt="" srcset="" />
        <img v-if="batterRoom[index - 1]" style="padding: 1.5px" class="people" :src="batterRoom[index - 1].user?.avatar" alt="" width="100%" />
        <div class="people" :class="`people-show${item.status}`" v-else></div>
        <img src="@/assets/images/battle/icon3.png" alt="" class="icon3" v-if="batterRoom[index - 1] && batterRoom[index - 1].show" />
      </div>
    </div>
    <div class="room-btn" :class="`room-btn${item.status}`">
      <span v-if="item.status == 0">
        立即加入
        <Cprice :size="0.1" :price="item.total_bean" />
      </span>
      <span v-else>
        {{ item.status == 2 ? "已结束" : "立即观看" }}
      </span>
    </div>
    <div class="good-box2">
      <img class="good-item" :src="_.box && _.box.intact_cover" alt="" v-for="_ in item.game_arena_box" :key="_.id" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => { },
    },
  },
  computed: {
    batterRoom() {
      let maxRoom = 0;
      const { item } = this;
      item.game_arena_player.forEach((item) => {
        if (item.seat > maxRoom) {
          maxRoom = item.seat;
        }
      });
      const array = new Array(maxRoom).fill(undefined);
      item.game_arena_player.forEach((item) => {
        array[item.seat] = item;
      });
      return array;
    },
  },
  methods: {
    ImgRoute(str) {
      return localStorage.getItem("static_file_url") + str;
    },
    itemClick(status, id) {
      let parseStatus = status;
      if (status == 1) {
        parseStatus = 0;
      }
      this.$router.push({
        path: "/vsbox",
        query: {
          id: id,
          Code: parseStatus,
        },
      });
      // this.$message({
      //   message: window,
      //   type: "success",
      // });
      // window.open(routeUrl.href, "_self");
    },
  },
};
</script>
<style lang="scss" scoped>
.status0 {
  background: url(../../../assets/images/battle/room-blue2-new.png) no-repeat;
  background-size: 100% 100%;

  .people {
    background: url(../../../assets/images/battle/yuan1-new.png) no-repeat;
    background-size: 100% 100%;
  }
}

.status1 {
  background: url(../../../assets/images/battle/room-purple2-new.png) no-repeat;
  background-size: 100% 100%;

  .people {
    background: url(../../../assets/images/battle/yuan2.png) no-repeat;
    background-size: 100% 100%;
  }
}

.status2 {
  background: url(../../../assets/images/battle/room-red2-new.png) no-repeat;
  background-size: 100% 100%;

  .people {
    background: url(../../../assets/images/battle/yuan3.png) no-repeat;
    background-size: 100% 100%;
  }
}

.room-btn1 {
  background: url(../../../assets/images/battle/new-btn-bg2-new.png) no-repeat;
  background-size: 100% 100%;
}

.room-btn2 {
  background: url(../../../assets/images/battle/new-btn-bg3-new.png) no-repeat;
  background-size: 100% 100%;
}

.room-btn0 {
  background: url(../../../assets/images/battle/new-btn-bg1-new.png) no-repeat;
  background-size: 100% 100%;
}

.item-mod {
  width: 1.65rem;
  height: 0.94rem;
  position: relative;

  .text-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 0.06rem 0.07rem 0rem;
    font-size: 0.08rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #c2c2c2;
  }

  .people-num {
    display: flex;
    justify-content: center;
    position: relative;
    margin-top: -0.02rem;
    margin-bottom: 0.04rem;
    .user {
      position: relative;
      // margin-right: 0.13rem;
      margin: 0 0.065rem;

      .winner {
        position: absolute;
        top: -0.07rem;
        left: 0.08rem;
        width: 0.09rem;
        z-index: 9;
      }

      .icon3 {
        position: absolute;
        left: 50%;
        transform: translate(-50%, -110%);
        z-index: 1;
        width: 0.25rem;
        height: 0.2rem;
      }
    }

    .people {
      width: 0.25rem;
      height: 0.25rem;
      border-radius: 50%;
      overflow: hidden;
      position: relative;

      // &::after,
      // &::before {
      //   position: absolute;
      //   content: "";
      //   width: 0.35rem;
      //   height: 0.1rem;
      //   top: 50%;
      //   left: 50%;
      //   transform: translate(-50%, -50%);
      // }

      // &::before {
      //   transform: translate(-50%, -50%) rotate(90deg);
      // }
    }
  }

  .room-btn {
    width: 1.04rem;
    height: 0.2rem;
    line-height: 0.2rem;
    margin: 0rem auto 0rem;
    // border-radius: 0.rem;
    font-size: 0.1rem;
    color: #ffffff;
    text-align: center;
    cursor: pointer;
    // padding: 0 0.02rem;

    span {
      display: flex;
      justify-content: center;
      align-items: center;
      // font-family: PingFang SC, PingFang SC;
      // font-weight: 400;
      // font-size: 0.1rem;
      // color: #ffffff;
      // font-style: normal;
      // text-transform: none;
    }

    .price {
      color: #ff9000;
    }
  }

  .good-box2 {
    width: 94%;
    overflow: hidden;
    // padding: 0rem 0.08rem;
    box-sizing: border-box;
    display: flex;
    // justify-content: space-around;
    align-items: center;
    position: absolute;
    bottom: 0.014rem;
    padding: 0 0.1rem;
    box-sizing: border-box;
    // left: 10%;
    img {
      max-height: 0.24rem;
      width: 0.24rem;
    }
  }
}

.people-show1 {
  background: #99ccff;

  &::after,
  &::before {
    background: #416dc5;
  }
}

.people-show2 {
  background: #bd3a3a;

  &::after,
  &::before {
    background: #f37878;
  }
}

.people-show0 {
  background: #4d0884;

  &::after,
  &::before {
    background: #9b61ca;
  }
}
</style>
